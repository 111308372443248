/* Base Colors */
:root {
  --background-dark: #1e1e2f; /* Dark coding editor background */
  --background-light: #2a2a40; /* Secondary background */
  --primary-blue: #007acc; /* Highlight blue (inspired by VS Code) */
  --secondary-purple: #c586c0; /* Soft purple for accents */
  --accent-green: #89d185; /* Success and emphasis */
  --warning-orange: #d19a66; /* Warnings or highlights */
  --error-red: #e06c75; /* Error or alert */
  --neutral-gray: #c9cfda; /* Text and icons */
  --neutral-light-gray: #dcdfe4; /* Borders and muted elements */
  --neutral-dark-gray: #3e4451; /* Shadows or depth */
}

body {
  background: var(--background-dark);
}

.button-theme{
  background-color: var(--background-light);
  color: var(--neutral-gray);
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  border-color: var(--secondary-purple);
  border-style: solid;
  border-width: 2px;
  box-shadow: 0px 2px 3px 1px var(--secondary-purple);
  appearance: none;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.button-theme:hover {
  background-color: var(--background-dark);
  border-width: 4px !important;
  border-color: var(--primary-blue);
  box-shadow: 0px 6px 8px 1px var(--primary-blue);
}

p {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

p, h2, h4, h5, hr {
  color: var(--neutral-gray);
}