.portfolio-piece-img {
    border-color: var(--secondary-purple);
    border-width: 2px;
    padding: 0;
    box-shadow: 0px 0px 4px var(--secondary-purple);
}

.card-title, .portfolio-piece {
    color: var(--neutral-light-gray) !important;
}

.card-subtitle, ul li{
    color: var(--neutral-gray) !important;
}

.portfolio-card {
    background-color: var(--background-light);
    border-color: var(--secondary-purple);
    border-width: 4px;
}

@media (max-width: 767px) {
    .portfolio-card {
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media (min-width: 768px) {
    .portfolio-card {
        box-shadow: -5px 5px 8px 2px var(--secondary-purple);
        transition: all 0.2s ease;
    }

    .portfolio-card:hover {
        background-color: var(--background-dark);
        border-color: var(--primary-blue);
        box-shadow: -5px 5px 8px 2px var(--primary-blue);
        transform: scale(1.05);
    }
    
    .portfolio-card:hover .portfolio-piece-img {
        border-color: var(--primary-blue);
    }
}



a, a:hover {
    text-decoration: none;
    color: black;
}

.card-list {
    justify-content: center;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 400px);
    gap: 30px;
}

.card {
    padding: 0px 10px 0px 10px;
}