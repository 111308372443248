nav {
    background: var(--background-light);
}

#toggle-button, #toggle-button:active, #toggle-button:focus{
    box-shadow: none;
    border: none;
}

.navbar-brand, .navbar-brand:hover, .navbar-link {
    color: var(--neutral-light-gray);
    padding: 10px 30px 10px 0px;
    text-decoration: none;
}

.navbar-link, .navbar-brand, .navbar-link-highlighted {
    transition: all 0.3s ease;
}

.navbar-link-highlighted, .navbar-link-highlighted:hover, .navbar-link:hover  {
    padding: 10px 30px 10px 0px;
    text-decoration: none;
    color: var(--neutral-light-gray);
}

.navbar-brand {
    font-weight: 700;
}

.navbar-link-highlighted {
    text-shadow: 0px 0px 50px var(--primary-blue), 0px 0px 10px var(--primary-blue);
}

.navbar-link-highlighted:hover, .navbar-link:hover, .navbar-brand:hover {
    text-shadow: 0px 0px 50px var(--primary-blue), 0px 0px 10px var(--primary-blue);
}

@media (min-width: 768px) {
    .navbar-link-highlighted:hover, .navbar-link:hover, .navbar-brand:hover {
        transform: scale(1.08);
    }
}